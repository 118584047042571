<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      :headers="headers"
      :show-operations="false"
      no-meta-check
      :buttons="computedButtons"
      :resource-query="resourceQuery"
      :custom-resource="customResource()"
      resource="productGroups"
      route-base="/reports/statistics/product_groups"
      :transform-fetched-data="transformData"
      show-filter
      do-route-filters
      :locale="locale"
      prune-search-filters
      :search-filters="filtersList"
      headers-filterable
      :headers-config="headersConfig"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import Decimal from 'decimal.js-light'
import { mapGetters } from 'vuex'
import qs from 'qs'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import {
  applyFiltersBeforeRouteEnter,
  formatDateRange,
  getDateTimeFormat,
  getRangeFor
} from '@/utils/date'
import { useExportsStore } from '@/store/exports'

function parseStaffName(staff) {
  return `${staff.staff_number || ''} ${
    staff.staff_number && (staff.firstname || staff.lastname) ? '-' : ''
  } ${`${staff.firstname || ''} ${staff.lastname || ''}`.trim() || ''}`.trim()
}

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.productGroups.productGroups')
    }
  },
  beforeRouteEnter: (to, _, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, _, next) {
    this.$emit('route-filter', safeGet(qs.parse(to.query), 'filter') || {})
    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.statistics.product-groups'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      loadingExport: false,
      filters: {},
      width: 500,
      headers: [
        {
          label: this.$t(
            'pages.reports.statistics.product_groups.product_number'
          ),
          field: 'product_group_id',
          minWidth: 160,
          fallback: '-'
        },
        {
          label: this.$t('common.headers.name.title'),
          field: 'description',
          fallback: '-',
          truncate: true,
          minWidth: 180
        },
        {
          label: this.$t('pages.reports.statistics.products.qty'),
          field: 'qty',
          align: 'right',
          truncate: true,
          minWidth: 120,
          fallback: '-',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.products.top_saleman'),
          field: 'top_sale_rep',
          truncate: true,
          minWidth: 200,
          fallback: '-',
          formatter: (row) => {
            if (!row.top_sale_rep) return '-'
            const staff = {
              firstname: row.firstname,
              lastname: row.lastname,
              staff_number: row.staff_number
            }
            if (!staff.firstname && !staff.lastname) return '-'
            return parseStaffName(staff) || '-'
          }
        },
        {
          label: this.$t('pages.reports.statistics.products.net_revenue'),
          field: 'net_revenue',
          align: 'right',
          fallback: '-',
          truncate: true,
          minWidth: 140,
          formatter: (row) => {
            if (Number.isFinite(row.net_revenue)) {
              return this.$formatCurrency(row.net_revenue, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.revenue'),
          field: 'revenue',
          align: 'right',
          fallback: '-',
          minWidth: 140,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.revenue)) {
              return this.$formatCurrency(row.revenue, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.total_discount'),
          field: 'total_discount',
          align: 'right',
          truncate: true,
          minWidth: 150,
          formatter: (row) => {
            if (Number.isFinite(row.total_discount)) {
              return this.$formatCurrency(row.total_discount, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        }
      ],
      revenueOptions: [
        {
          label: this.$t('pages.reports.statistics.products.revenue'),
          value: 'revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.net_revenue'),
          value: 'net_revenue',
          type: 'currency'
        }
      ],
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:product_groups:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: () => {
            this.handleExport()
          }
        }
      ],
      summary: null
    }
  },
  computed: {
    ...mapGetters({
      branchNumber: 'Config/getCurrentBranchNumber',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      currentLocation: 'Config/getCurrentLocation'
    }),
    filtersList() {
      return [
        {
          name: 'product_group_id',
          type: 'multiselect',
          label: this.$t(
            'pages.reports.statistics.product_groups.product_number'
          ),
          placeholder: this.$t('common.inputs.placeholders.select'),
          optionsValue: 'product_group_id',
          computedFields: ['product_group_id', 'name'],
          resource: 'productGroups',
          fetchHandler: 'getAll',
          modifyQuery: (q) => ({ q, limit: 50, deleted: false }),
          filterable: true,
          doInitialFetch: true
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          dateTimeMode: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    parsedDate() {
      return this.parsedQuery.date || {}
    },
    date() {
      return {
        start: new Date(this.parsedDate.start),
        end: new Date(this.parsedDate.end),
        showDateText: true
      }
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        branch_number: this.branchNumber || undefined,
        branch_group: this.parsedQuery.branch_group || undefined,
        end: this.parsedDate.end || undefined,
        start: this.parsedDate.start || undefined,
        product_group_id: this.parsedQuery.product_group_id || undefined
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    customResource() {
      const self = this
      return {
        getAll(params) {
          return new Promise((resolve, reject) => {
            th.analytics()
              .getProductGroups(self.resourceQuery)
              .then(({ data }) => {
                const list =
                  data &&
                  data.find(
                    (d) => safeGet(d, 'metric.job') === 'reports_product_groups'
                  )
                const summary =
                  data &&
                  data.find(
                    (d) =>
                      safeGet(d, 'metric.job') ===
                      'reports_product_groups.summary'
                  )

                if (summary && summary.values) {
                  if (Array.isArray(summary.values)) {
                    self.summary = summary.values
                  } else {
                    self.summary = [summary.values]
                  }
                }

                if (list && list.values) {
                  resolve({
                    data: list.values
                  })
                }

                resolve({
                  data: []
                })
              })
              .catch((error) => reject(error))
          })
        }
      }
    },
    handleLoadingError() {},
    transformData(data) {
      return data
    },
    getCellValue(header, row) {
      const cellValue = safeGet(row, header.value)
      const type = header.type
      if (header.value === 'sales_rep') {
        return this.$formatStaff(row) || '--'
      } else if (cellValue === null) {
        return this.$t('pages.reports.statistics.products.null')
      } else if (type === 'number') {
        const d = new Decimal(cellValue)
        return d.toDecimalPlaces(4).toString()
      } else {
        return cellValue
      }
    },
    async handleExport() {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')
      const query = {
        ...this.resourceQuery,
        format: 'csv'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsProductGroups.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.productGroups.productGroups',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsProductGroups',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t(
              'pages.reports.statistics.productGroups.productGroups'
            )
          })
        })
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<style scoped></style>
